import React from 'react';
import PeoplesGrid from '../components/PeoplesGrid/PeoplesGrid';
import { useTeamMembers } from '../hooks/useTeamMembers';
import { Block, Content, PostHeading } from '../components/common';

const NotreEquipePage = () => {
    const { teamMembers, isLoading, error } = useTeamMembers();

    if (isLoading) return <p>Chargement...</p>;
    if (error) return <p>Erreur: {error.message}</p>;
    console.log(teamMembers);
    return (
        <Block>
            <Content>
                <PostHeading>Notre équipe</PostHeading>
                <PeoplesGrid members={teamMembers} />
            </Content>
        </Block>
    );
};

export default NotreEquipePage;
