import React, { useState, useEffect } from 'react';
import {
  ScrollableContainer,
  SubHeading,
  Card,
  Paragraph,
  Content,
  Redirect,
  Image,
  PostHeading,
} from '../../components/common';
import { usePosts } from '../../hooks/usePosts';
import imageUrlBuilder from '@sanity/image-url';
import { sanityClient } from '../../utils/sanity/client';

import {
  PostsContainer,
  FeaturedPostContainer,
  RecentPostsContainer,
  RecentPostItem,
  RecentPostImage,
  RecentPostTitle,
} from './PostsList.styles';
import { color } from 'framer-motion';
import { Link } from 'react-router-dom';

function urlFor(source) {
  return imageUrlBuilder(sanityClient).image(source);
}

export const PostsList = () => {
  const { posts, isLoading, error } = usePosts();
  const [displayedPosts, setDisplayedPosts] = useState([]);

  useEffect(() => {
    if (!isLoading && !error && posts && posts.length > 0) {
      // Sort posts by _updatedAt in descending order
      const sortedPosts = [...posts].sort(
        (a, b) => new Date(b._updatedAt) - new Date(a._updatedAt)
      );
      // Set displayedPosts to the top 5 posts
      setDisplayedPosts(sortedPosts.slice(0, 5));
    }
  }, [isLoading, error, posts]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!posts || posts.length === 0) return <div>No posts available.</div>;

  const featuredPost = displayedPosts[0];
  const recentposts = displayedPosts.slice(1);

  const getFirstImageUrl = (content) => {
    if (!content || !Array.isArray(content)) return null;
    const imageBlock = content.find(
      (block) => block._type === 'image' && block.asset && block.asset._ref
    );
    return imageBlock
      ? urlFor(imageBlock.asset._ref)
        .width(800)
        .fit('max')
        .auto('format')
        .url()
      : null;
  };

  const getExcerpt = (content) => {
    if (!content || !Array.isArray(content)) return 'No content available';

    let excerpt = '';
    let lineCount = 0;

    for (const block of content) {
      if (block._type === 'block' && block.children) {
        for (const child of block.children) {
          if (child._type === 'span' && child.text) {
            const lines = child.text.split('\n');
            for (const line of lines) {
              if (lineCount < 3) {
                excerpt += line + ' ';
                lineCount++;
              } else {
                break;
              }
            }
            if (lineCount >= 3) break;
          }
        }
      }
      if (lineCount >= 3) break;
    }

    excerpt = excerpt.trim();
    if (excerpt.length > 300) {
      excerpt = excerpt.slice(0, 300) + '...';
    } else if (excerpt.length > 0) {
      excerpt += '...';
    }

    return excerpt || 'No content available';
  };

  return (
    <Content>
      <PostHeading>Publications récentes
        <Link to="/publication">
          <svg width="20" height="20" fill="#5d7dcf" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M5 5v14h14v-7h2v7c0 1.1-.9 2-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7v2H5Zm9 0V3h7v7h-2V6.41l-9.83 9.83-1.41-1.41L17.59 5H14Z" clip-rule="evenodd"></path>
          </svg>
        </Link>
      </PostHeading>
      <br />
      <PostsContainer>
        <FeaturedPostContainer recentposts={recentposts.length > 0}>
          {featuredPost && (
            <Card>
              {featuredPost.content && getFirstImageUrl(featuredPost.content) !== null  && (
                <Image
                  src={getFirstImageUrl(featuredPost.content)}
                  alt={featuredPost.title || 'Featured post'}
                  width="100%"
                  height="200px"
                  style={{ objectFit: 'cover' }}
                />
              )}
              <RecentPostTitle>{featuredPost.title || 'Untitled Post'}</RecentPostTitle>
              <Paragraph>
                {featuredPost._updatedAt
                  ? new Date(featuredPost._updatedAt).toLocaleDateString(
                    'fr-FR',
                    {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }
                  )
                  : 'Date not available'}
              </Paragraph>
              <Paragraph>{getExcerpt(featuredPost.content)}</Paragraph>
              <Redirect
                to={`/publication#${featuredPost._id}`}
                rel="noopener noreferrer"
              >
                Apprendre d'avantage
              </Redirect>
            </Card>
          )}
        </FeaturedPostContainer>
        {recentposts.length > 0 && (
          <RecentPostsContainer>
            <ScrollableContainer>
              {recentposts.map((post) => (
                <RecentPostItem
                  key={post._id}
                  to={`/publication#${post._id}`}
                  rel="noopener noreferrer"
                >
                  {post.content && (
                    <RecentPostImage
                      src={getFirstImageUrl(post.content)}
                      alt={post.title || 'Recent post'}
                    />
                  )}
                  <div>
                    <RecentPostTitle>
                      {post.title || 'Untitled Post'}
                    </RecentPostTitle>
                  </div>
                </RecentPostItem>
              ))}
            </ScrollableContainer>
          </RecentPostsContainer>
        )}
      </PostsContainer>
    </Content>
  );
};
