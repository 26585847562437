import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
import { sanityClient } from '../../utils/sanity/client';
import {
    GridContainer,
    GridItem,
    Image,
    Name,
    Position,
    Description,
    Link,
    } from './PeoplesGrid.styles';

// Image URL builder function
function urlFor(source) {
  return imageUrlBuilder(sanityClient).image(source);
}

const PeoplesGrid = ({ members }) => (
  <GridContainer>
    {members.map((member, index) => (
      <GridItem key={index}>
        {member.image?.asset ? (
          <Image
            src={urlFor(member.image.asset)
              .width(300) 
              .height(300) 
              .fit('crop')
              .auto('format')
              .url()}
            alt={member.image.alt || 'Member image'}
          />
        ) : (
            <Image
            src={'https://placehold.co/300'}
            alt={'Member image'}
          />
        )}
        <Name>{member.nom}</Name>
        <Position>{member.poste}</Position>
        <Description>{member.description}</Description>
        {member.liens && member.liens.map((link, idx) => (
          <Link key={idx} href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </Link>
        ))}
      </GridItem>
    ))}
  </GridContainer>
);

export default PeoplesGrid;
