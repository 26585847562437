import styled from "styled-components";
import { Link } from 'react-router-dom';
import { Image, SubHeading } from "../common";

export const PostsContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FeaturedPostContainer = styled.div`
  flex: 1;
  border-right: ${props => props.hasRecentPosts ? '1px solid #ddd' : 'none'};

    @media (max-width: 768px) {
    border-right: none;
    }
`;

export const RecentPostsContainer = styled.div`
  flex: 1;
`;

export const RecentPostItem = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #ddd;
  min-height: 150px;
  padding: 1rem 2rem;

  &:hover {
    background-color: #f9f9f9;
  }

  &:last-child {
    border-bottom: none;
  }

`;

export const RecentPostImage = styled(Image)`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

export const RecentPostTitle = styled(SubHeading)`
  font-size: 1.3rem;
  margin: 0;
  color: #6a7fca;
`;