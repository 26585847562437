import React, { useEffect, useState } from "react";
import {
  HeaderContainer,
  HeaderContent,
  HeaderLink,
  HeaderLogo,
  HeaderLogoMobile,
  HeaderNav,
  MobileLink,
  HeaderDropdown,
  DropdownContent,
  MobileDropdownContent
} from "./Header.styles";
import logo from "../../assets/media/logoCDC.svg";
import { bubble as Menu } from "react-burger-menu";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const { pathname } = useLocation();
  const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const closeMenu = () => {
    document.querySelector("#react-burger-cross-btn").click();
  };

  const toggleMobileDropdown = () => {
    setMobileDropdownOpen(!isMobileDropdownOpen);
  };

  const isActive = (linkPath) => pathname === linkPath;

  return (
    <>
      {/* Mobile Menu */}
      <Menu
        disableOverlayClick
        bodyClassName="menu-body"
        itemListClassName="menu-list"
        className="menu-container"
        crossClassName="menu-cross"
        overlayClassName="menu-overlay"
        burgerButtonClassName={`menu-burger`}
      >
        <Link to="/">
          <HeaderLogoMobile src={logo} alt="CDC RDN" />
        </Link>
        <MobileLink onClick={closeMenu} to="/" className={isActive("/") ? "active" : ""}>Accueil</MobileLink>
        
        <MobileLink onClick={toggleMobileDropdown} className={isActive("/organisation") ? "active" : ""}>Notre organisme</MobileLink>
        <MobileDropdownContent className={isMobileDropdownOpen ? "open" : ""}>
          <MobileLink onClick={closeMenu} to="/notre-equipe" >Notre Équipe</MobileLink>
          <MobileLink onClick={closeMenu} to="/conseil-administration" >Membres du CA</MobileLink>
        </MobileDropdownContent>

        <MobileLink onClick={closeMenu} to="/membres" className={isActive("/membres") ? "active" : ""}>Membres</MobileLink>
        <MobileLink onClick={closeMenu} to="/projets-collectifs" className={isActive("/projets-collectifs") ? "active" : ""}>Projets collectifs</MobileLink>
        <MobileLink onClick={closeMenu} to="/publication" className={isActive("/publication") ? "active" : ""}>Publications</MobileLink>
        <MobileLink onClick={closeMenu} to="/contact" className={isActive("/contact") ? "active" : ""}>Nous joindre</MobileLink>
      </Menu>

      {/* Desktop Header */}
      <HeaderContainer>
        <HeaderContent>
          <Link to="/">
            <HeaderLogo src={logo} alt="CDC RDN" />
          </Link>
          <HeaderNav>
            <HeaderLink to="/" className={isActive("/") ? "active" : ""}>Accueil</HeaderLink>

            <HeaderDropdown>
              <HeaderLink to="/organisation" className={isActive("/organisation") ? "active" : ""}>Notre organisme</HeaderLink>
              <DropdownContent className="dropdown-content">
                <Link to="/notre-equipe" >Notre Équipe</Link>
                <Link to="/conseil-administration">Membres du CA</Link>
              </DropdownContent>
            </HeaderDropdown>

            <HeaderLink to="/membres" className={isActive("/membres") ? "active" : ""}>Membres</HeaderLink>
            <HeaderLink to="/projets-collectifs" className={isActive("/projets-collectifs") ? "active" : ""}>Projets collectifs</HeaderLink>
            <HeaderLink to="/publication" className={isActive("/publication") ? "active" : ""}>Publications</HeaderLink>
            <HeaderLink to="/contact" className={isActive("/contact") ? "active" : ""}>Nous joindre</HeaderLink>
          </HeaderNav>
        </HeaderContent>
      </HeaderContainer>
    </>
  );
}
