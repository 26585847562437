import { useEffect, useState } from 'react';
import { sanityClient } from '../utils/sanity/client';

export function useAdminMembers() {
  const [adminMembers, setAdminMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const query = '*[_type == "ConseilAdministration"]';

    sanityClient.fetch(query)
      .then(members => {
        setAdminMembers(members);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { adminMembers, isLoading, error };
}
