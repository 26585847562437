import styled from 'styled-components';

const GridContainer = styled.div`
width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  padding: 20px;
`;

const GridItem = styled.div`
  text-align: center;
  border-radius: 4px;
  padding: 16px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
`;

const Name = styled.h3`
  margin-top: 10px;
  font-size: 1.2em;
`;

const Position = styled.p`
  font-size: 0.8em;
  font-weight: bold;
`;

const Description = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0.8em;
  color: #666;
  white-space: pre-wrap;
`;

const Link = styled.a`
  display: block;
  color: #007bff;
  text-decoration: none;
  font-size: 0.8em;

  &:hover {
    text-decoration: underline;
  }
`;

export { GridContainer, GridItem, Image, Name, Position, Description, Link };