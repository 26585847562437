import React from 'react';
import {
    Content,
    MainHeading,
    Paragraph,
    Separator,
} from '../components/common';
import { AdvancedMarker, APIProvider, Map, Pin } from '@vis.gl/react-google-maps';
import { motion } from 'framer-motion'; // Import Framer Motion

const ContactUs = () => {
    const fadeInUp = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    };

    const fadeIn = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.8 } },
    };

    return (
        <Content>
            <motion.div initial="hidden" animate="visible" variants={fadeInUp}>
                <MainHeading>Nous Joindre</MainHeading>
            </motion.div>

            <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{ delay: 0.3 }}
                style={{ marginBottom: '2rem' }}
            >
                <APIProvider apiKey={'AIzaSyBYQDicnuFh-o2pb0xYP_H2yRWIzC_4Wv8'}>
                    <Map
                        mapId={'aa017ccafef86e4d	'}
                        style={{ width: '100%', height: '400px', outline: 'none', border: 'none' }}
                        defaultCenter={{ lat: 45.753229, lng: -73.979645 }}
                        defaultZoom={14}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                    >
                        <AdvancedMarker position={{ lat: 45.753229, lng: -73.979645 }}>
                            <Pin
                                background={'#e97ba1'}
                                borderColor={'#e97ba1'}
                                glyphColor={'#FFF'}
                            />
                        </AdvancedMarker>
                    </Map>
                </APIProvider>
            </motion.div>
            <Separator />
            <motion.div initial="hidden" animate="visible" variants={fadeIn}>
                <Paragraph>
                    Corporation de développement communautaire de la Rivière-du-Nord (CDC RDN)
                </Paragraph>
                <Paragraph>1270, boulevard des Laurentides, Saint-Jérôme, Québec, J7Z 7L3</Paragraph>
                <Separator />
                <Paragraph>Téléphone : <a href="tel:450-912-8005">450-912-8005</a></Paragraph>
                <Paragraph>
                    Courriel : <a href="mailto:direction@cdcrdn.org">direction@cdcrdn.org</a>
                </Paragraph>
                <Paragraph>
                    Facebook : <a href="https://www.facebook.com/profile.php?id=100070001537907">CDC Rivière-du-Nord </a>
                </Paragraph>
            </motion.div>
        </Content>
    );
};

export default ContactUs;
