import styled from "styled-components";

export const FAQItem = styled.div`
    position: relative;
    padding: 16px;
    padding-right: 32px;
    border: 1px solid var(--colored-background);
    background: var(--colored-background);
    ${props => props.isopen && `border-bottom: 0 !important;`}
    border-radius: 4px;
    ${props => props.isopen && `border-bottom-left-radius: 0; border-bottom-right-radius: 0;`}
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    width: 800px;
    color: #fff;

    & p:not(:has(strong)), & ul{
        margin-top: 2rem;
    }

    &:hover {
       opacity: 0.8;
    }

    @media (max-width: 1200px) {
        width: 600px;
    }

    @media (max-width: 768px) {
        width: 100%;
        font-size: 0.9rem;
    }

    svg{
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        height: 20px;
        width: 20px;
        fill: none;
        stroke: #fff;

        ${props => props.isopen && `
            transform: translateY(-50%) rotate(180deg);
        `}
    }
`;

export const Content = styled.div`
    padding: 16px;
    border: 1px solid #00000020;
    border-top: 0 !important;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 800px;

    @media (max-width: 1200px) {
        width: 600px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;