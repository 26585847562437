import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  MainHeading,
  PostHeading,
  InfoHeading,
  Paragraph,
  Image,
  Article,
  Redirect,
  Text,
  List,
  ListItem,
  Blockquote,
  BlogContent,
  ArticlesList,
} from '../common';
import { usePosts } from '../../hooks/usePosts';
import imageUrlBuilder from '@sanity/image-url';
import { sanityClient } from '../../utils/sanity/client';
import BlockContent from '@sanity/block-content-to-react';

// Function to build image URLs
function urlFor(source) {
  return imageUrlBuilder(sanityClient).image(source);
}

// Styled components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }

  @media (max-width: 480px) {
    padding: 0 5px;
  }
`;

const FilterContainer = styled.div`
  margin-bottom: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
`;

const FilterTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #333;
`;

const FilterForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 200px;

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

const Label = styled.label`
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #666;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    outline: none;
    border-color: #5577ce;
    box-shadow: 0 0 0 3px rgba(0, 122, 255, 0.1);
  }
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;

  &:active {
    transform: scale(0.98);
  }
`;

const ApplyButton = styled(Button)`
  background-color: #5577ce;
  color: white;

  &:hover {
    background-color: #0056b3;
  }
`;

const ResetButton = styled(Button)`
  background-color: #f2f2f7;
  color: #5577ce;

  &:hover {
    background-color: #e5e5ea;
  }
`;

const BlogPostsComponent = () => {
  const { posts, isLoading, error } = usePosts();
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [page, setPage] = useState(1);
  const loader = useRef(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [targetPostId, setTargetPostId] = useState(null);

  const postsPerPage = 10;

  // Extract the fragment identifier (post ID) from the URL on component mount
  useEffect(() => {
    const hash = window.location.hash.substring(1); // Remove the '#' character
    if (hash) {
      setTargetPostId(hash);
    }
  }, []);

  // Intersection Observer for infinite scrolling
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loader]);

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  };

  // Update displayed posts when posts, page, or date filters change
  useEffect(() => {
    if (posts) {
      // Sort posts by _updatedAt in descending order
      const sortedPosts = [...posts].sort(
        (a, b) => new Date(b._updatedAt) - new Date(a._updatedAt)
      );

      // Apply date range filter
      const filteredPosts = filterPostsByDateRange(
        sortedPosts,
        startDate,
        endDate
      );

      // Find the index of the target post (if any)
      let targetIndex = -1;
      if (targetPostId) {
        targetIndex = filteredPosts.findIndex(
          (post) => post._id === targetPostId
        );
      }

      // Calculate the required page number to include the target post
      let requiredPage = page;
      if (targetIndex !== -1) {
        requiredPage = Math.ceil((targetIndex + 1) / postsPerPage);
      }

      // Update displayed posts
      setDisplayedPosts(
        filteredPosts.slice(0, Math.max(requiredPage, page) * postsPerPage)
      );

      // Update the page state if necessary
      if (requiredPage > page) {
        setPage(requiredPage);
      }
    }
  }, [posts, page, startDate, endDate, targetPostId]);

  // Scroll to the target post after it has been rendered
  useEffect(() => {
    if (
      targetPostId &&
      displayedPosts.some((post) => post._id === targetPostId)
    ) {
      const element = document.getElementById(targetPostId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTargetPostId(null); // Optional: reset after scrolling
      }
    }
  }, [displayedPosts, targetPostId]);

  // Function to filter posts by date range
  const filterPostsByDateRange = (posts, start, end) => {
    if (!start && !end) return posts;

    return posts.filter((post) => {
      const postDate = new Date(post.publishedAt);
      const startDateObj = start ? new Date(start) : null;
      const endDateObj = end ? new Date(end) : null;

      if (startDateObj && endDateObj) {
        return postDate >= startDateObj && postDate <= endDateObj;
      } else if (startDateObj) {
        return postDate >= startDateObj;
      } else if (endDateObj) {
        return postDate <= endDateObj;
      }
      return true;
    });
  };

  // Handle the Apply button click
  const handleFilter = () => {
    setPage(1);
    const filteredPosts = filterPostsByDateRange(posts, startDate, endDate);
    setDisplayedPosts(filteredPosts.slice(0, postsPerPage));
  };

  // Handle the Reset button click
  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setPage(1);
    setDisplayedPosts(posts.slice(0, postsPerPage));
  };

  // Render loading or error states
  if (isLoading && displayedPosts.length === 0)
    return <Paragraph>Loading...</Paragraph>;
  if (error) return <Paragraph>Error: {error.message}</Paragraph>;

  // Define serializers for BlockContent
  const serializers = {
    types: {
      block: ({ node }) => {
        const { style = 'normal', markDefs = [] } = node;

        const content = node.children.map((child, index) => {
          if (child._type === 'span') {
            if (child.text === '') {
              return <br key={index} />;
            }

            const linkMark = child.marks.find((mark) =>
              markDefs.find((def) => def._key === mark && def._type === 'link')
            );
            if (linkMark) {
              const linkDef = markDefs.find((def) => def._key === linkMark);
              return (
                <Redirect
                  key={index}
                  to={linkDef.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {child.text}
                </Redirect>
              );
            } else {
              return (
                <span key={index} style={{ whiteSpace: 'pre-wrap' }}>
                  {child.text}
                </span>
              );
            }
          } else if (child._type === 'hardBreak') {
            return <br key={index} />;
          }
          return null;
        });

        const WrapperComponent =
          style === 'normal'
            ? Text
            : style === 'h1'
            ? PostHeading
            : style === 'h2'
            ? InfoHeading
            : style === 'blockquote'
            ? Blockquote
            : Text;

        return (
          <WrapperComponent
            style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
          >
            {content}
          </WrapperComponent>
        );
      },
      image: ({ node }) => (
        <Image
          src={urlFor(node.asset._ref)
            .width(800)
            .fit('max')
            .auto('format')
            .url()}
          alt={node.alt || ' '}
          loading="lazy"
          width={node.size}
        />
      ),
    },
    list: (props) => <List>{props.children}</List>,
    listItem: (props) => <ListItem>{props.children}</ListItem>,
    hardBreak: () => <br />,
    container: ({ children }) => <div>{children}</div>,
    unknownType: ({ type }) => <div>Unknown block type: {type}</div>,
    unknownMark: ({ type, children }) => <span>{children}</span>,
  };

  // Render the component
  return (
    <Container>
      <FilterContainer>
        <FilterTitle>Filter par date</FilterTitle>
        <FilterForm>
          <InputGroup>
            <Label htmlFor="startDate">Date de début</Label>
            <Input
              id="startDate"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <Label htmlFor="endDate">Date de fin</Label>
            <Input
              id="endDate"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </InputGroup>
          <ApplyButton onClick={handleFilter}>Appliquer</ApplyButton>
          <ResetButton onClick={handleReset}>Réinitialiser</ResetButton>
        </FilterForm>
      </FilterContainer>
      <ArticlesList>
        {displayedPosts.map((post) => (
          <Article key={post._id} id={post._id}>
            <MainHeading>{post.title}</MainHeading>
            {post.publishedAt && (
              <Paragraph>
                Publié le :{' '}
                {new Date(post.publishedAt).toLocaleDateString('fr-FR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </Paragraph>
            )}
            <BlogContent>
              <BlockContent
                blocks={post.content}
                serializers={serializers}
                projectId={sanityClient.config().projectId}
                dataset={sanityClient.config().dataset}
                renderContainerOnSingleChild={true}
                ignoreUnknownTypes={false}
              />
            </BlogContent>
          </Article>
        ))}
        <div ref={loader}>
          {isLoading && <Paragraph>Loading more...</Paragraph>}
        </div>
      </ArticlesList>
    </Container>
  );
};

export default BlogPostsComponent;
