import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, useInView } from 'framer-motion'; // Import Framer Motion and useInView
import { Content, Block, JumboBlock, MainHeading, SubHeading, Paragraph, Inline, Button, Separator, InfoHeading, Column, HeroBlock } from '../components/common';
import { PostsList } from '../components/PostsList/PostsList';
import Marquee from "react-fast-marquee";
import HeroIllustration from '../assets/media/Illustration-accueil.svg';
import AnimatedInfoBlock from '../components/AnimatedInfoBlock/AnimatedInfoBlock';
import backgroundImage from '../assets/media/paysage.jpeg';
import { Link } from 'react-router-dom';


const HeroContent = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  max-width: 1200px; /* Ensure a reasonable max-width */
  margin: 0 auto; /* Center the content */

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const HeroText = styled.div`
  flex: 1;
`;

const HeroImage = styled.img`
  flex: 1;
  max-width: 45%;
  height: auto;

  @media (max-width: 768px) {
    max-width: 90%;
    margin-top: 2rem;
  }

  @media (max-width: 480px) {
      position: absolute;
    top: -35%;
    z-index: -1;
    opacity: 0.1;
    height: 400px;
  }
`;

const CreditsButton = styled.button`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #f1f5fe;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  z-index: 10;
`;

const CreditsDialog = styled.div`
  position: absolute;
  bottom: 10px;
  right: 8px;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  z-index: 20;
  display: ${props => props.isopen ? 'flex' : 'none'};
  flex-direction: column;
  gap: 1rem;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${props => props.isopen ? 'block' : 'none'};
  z-index: 15;
`;

const CloseButton = styled.button`
position: absolute;
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

export default function Home() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } },
  };

  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      <HeroBlock>
        <motion.div
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={fadeInUp}
          style={{ width: 'inherit' }}
        >
          <HeroContent ref={ref}>
            <HeroText>
              <MainHeading>Forte de sa<br />communauté</MainHeading>
              <Paragraph>
                La Corporation de développement communautaire de la Rivière-du-Nord rassemble avec fierté des organismes communautaires de la MRC de la Rivière-du-Nord, dans un esprit de collaboration et d’entraide au service de notre communauté.
              </Paragraph>
              <Separator />
            </HeroText>
            <HeroImage src={HeroIllustration} alt="Hero Illustration" />
          </HeroContent>
        </motion.div>
      </HeroBlock>

      <JumboBlock background={`url(${backgroundImage})`}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={fadeInUp}
          viewport={{ once: true }}
          style={{ maxWidth: '1200px', margin: '0 auto' }} /* Fixed width */
        >
          <Content>
            <SubHeading>La CDC Rivière-du-Nord</SubHeading>
            <Separator />
            <Paragraph>
              La Corporation de développement communautaire de la Rivière-du-Nord est un regroupement local et multisectoriel d'organismes communautaires de la MRC de la Rivière-du-Nord.
            </Paragraph>
          </Content>
        </motion.div>
        <Overlay isopen={isDialogOpen} onClick={toggleDialog} />
        <CreditsDialog isopen={isDialogOpen}>
          <CloseButton onClick={toggleDialog} name='close_credit'>&times;</CloseButton>
          <h3>Credits Photo</h3>
          <p>Photographe: Daniel Forget</p>
        </CreditsDialog>
        <CreditsButton onClick={toggleDialog} name='credit'>i</CreditsButton>
      </JumboBlock>

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeIn}
        viewport={{ once: true }}
        style={{ width: 'inherit' }} /* Inherit width */
      >
        <Block>
          <PostsList />
        </Block>
      </motion.div>

      <AnimatedInfoBlock />

      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={fadeInUp}
        viewport={{ once: true }}
        style={{ width: 'inherit' }} /* Inherit width */
      >
        <Marquee
          gradient={false}
          speed={40}
          pauseOnHover={true}
          style={{ height: "120px", fontSize: "3rem", padding: "0.3rem", color: "#fff", backgroundColor: "#6a7fca" }}
        >
          CDC Rivière-du-Nord, forte de sa communauté
        </Marquee>
      </motion.div>

      <Block background="var(--colored-background)">
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={fadeInUp}
          viewport={{ once: true }}
          style={{ width: 'inherit' }} /* Inherit width */
        >
          <Content>
            <Inline>
              <SubHeading>
                La CDC favorise la <br />
                <u>concertation</u> et la <u>diversité</u>
              </SubHeading>
              <Column>
                <InfoHeading>NOS ACTIONS MOBILISENT L'ACTION COMMUNAUTAIRE.</InfoHeading>
                <Paragraph>
                  Pour ce faire, la CDC développe des projets structurants à vocation sociale, en partenariat avec les municipalités, les citoyens, ainsi que l’ensemble des acteurs du milieu.
                </Paragraph>
                <Link to="/projets-collectifs">
                  <Button primary>
                    Projets collectifs
                  </Button>
                </Link>
              </Column>
            </Inline>
          </Content>
        </motion.div>
      </Block>
    </>
  );
}
