// ScrollUpButton.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled component for the button
const Button = styled.button`
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: #5577ce;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  font-size: 24px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: opacity 0.3s, transform 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    transform: scale(0.95);
  }
`;

const ScrollUpButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top when button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll
    });
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', toggleVisibility);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <Button onClick={scrollToTop} visible={isVisible} aria-label="Scroll to top">
    <svg width="32" height="32" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M5.25 15.375 12 8.625l6.75 6.75"></path>
</svg>
    </Button>
  );
};

export default ScrollUpButton;
