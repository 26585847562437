import React from 'react';
import PeoplesGrid from '../components/PeoplesGrid/PeoplesGrid';
import { useAdminMembers } from '../hooks/useAdminMembers';
import { Block, Content, PostHeading } from '../components/common';

const ConseilAdministrationPage = () => {
    const { adminMembers, isLoading, error } = useAdminMembers();

    console.log(adminMembers);
    console.log('adminMembers');

    if (isLoading) return <p>Chargement...</p>;
    if (error) return <p>Erreur: {error.message}</p>;

    return (
        <Block>
            <Content>
                <PostHeading>Membres du conseil d’administration</PostHeading>
                <PeoplesGrid members={adminMembers} />
            </Content >
        </Block>
    );
};

export default ConseilAdministrationPage;
