import React from "react";
import { motion } from "framer-motion";
import { useMembers } from "../../hooks/useMembers";
import imageUrlBuilder from "@sanity/image-url";
import { sanityClient } from '../../utils/sanity/client';
import styled from 'styled-components';

const MembersGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
`;

const ClickableMember = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
`;

const MemberWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const MemberLogo = styled.img`
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
`;

const MemberName = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
  font-size: 0.9rem;
  text-align: center;
  padding: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${ClickableMember}:hover & {
    opacity: 1;
  }
`;

function urlFor(source) {
  return imageUrlBuilder(sanityClient).image(source);
}

export default function MembersGrid() {
  const { members, isLoading, error } = useMembers();

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const memberList = members;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <MembersGridContainer>
        {memberList?.map((member) => (
          <motion.div key={member._id} variants={itemVariants}>
            <ClickableMember href={member.link || "#"} target="_blank" rel="noopener noreferrer">
              <MemberWrapper>
                <MemberLogo
                  src={urlFor(member.image).url()}
                  alt={member.image.alt}
                />
              </MemberWrapper>
              <MemberName>{member.name}</MemberName>
            </ClickableMember>
          </motion.div>
        ))}
      </MembersGridContainer>
    </motion.div>
  );
}