import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import OrganizationPage from "./pages/Organization";
import Members from "./pages/Members";
import ProjectsCollectifs from "./pages/Projects";
import ContactUs from "./pages/Contact";
import Posts from "./pages/Posts";
import ScrollUpButton from "./components/ScrollUpButton/ScrollUpButton";
import NotreEquipePage from "./pages/NotreEquipePage";
import ConseilAdministrationPage from "./pages/ConseilAdministrationPage";

export default function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="organisation" element={<OrganizationPage />} />
        <Route path="membres" element={<Members />} />
        <Route path="projets-collectifs" element={<ProjectsCollectifs />} />
        <Route path="publication" element={<Posts />} />
        <Route path="notre-equipe" element={<NotreEquipePage />} />
        <Route path="conseil-administration" element={<ConseilAdministrationPage />} />
        <Route path="contact" element={<ContactUs />} />
      </Routes>
      <ScrollUpButton />
      <Footer />
    </Router>
  );
}
