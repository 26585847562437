import React from "react";
import { Content, JumboBlock, MainHeading, Paragraph } from "../components/common";
import BlogPostsComponent from "../components/BlogPostsDisplay/BlogPostsComponent";

export default function Posts() {
  return (
    <JumboBlock>
      <Content>
        <MainHeading>Nos publications</MainHeading>
        <Paragraph>
        Rejoignez-nous sur Facebook pour ne rien manquer ! Suivez notre page pour découvrir les dernières actualités, événements et initiatives qui vous concernent. 
        </Paragraph>
      </Content>

      <Content>
        <BlogPostsComponent />
      </Content>
    </JumboBlock>
  );
}
