import React from 'react';
import { useEffect, useState } from 'react';
import { sanityClient } from '../utils/sanity/client';  
import styled from 'styled-components';

const DocumentListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const DocumentItem = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;

  &:hover {
    svg {
      color: #5b68a8;
    }
    a {
      color: #5b68a8;
    }
  }
`;

const DocumentIcon = styled.svg`
  height: 25px;
  width: 25px;
  color: #666;
  transition: color 0.2s ease;
`;

const DocumentLink = styled.a`
  color: #333;
  text-decoration: none;
  transition: color 0.2s ease;
`;

const LoadingMessage = styled.div`
  text-align: center;
  color: #666;
  padding: 1rem 0;
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #dc3545;
  padding: 1rem 0;
`;

const DocumentList = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const result = await sanityClient.fetch(
          `*[_type == "pdfs"] | order(uploadedAt desc) {
            title,
            "fileUrl": pdfFile.asset->url,
            "fileName": pdfFile.asset->originalFilename
          }`
        );
        setDocuments(result);
        setError(null);
      } catch (error) {
        console.error('Error fetching documents:', error);
        setError('Failed to load documents. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  if (loading) {
    return <LoadingMessage>Chargement</LoadingMessage>;
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (!documents.length) {
    return <LoadingMessage>Aucun rapports annuels disponibles</LoadingMessage>;
  }

  return (
    <DocumentListContainer>
      {documents.map((doc, index) => (
        <DocumentItem key={index}>
          <DocumentIcon
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
            />
          </DocumentIcon>
          <DocumentLink
            href={doc.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            download={doc.fileName}
          >
            {doc.title}
          </DocumentLink>
        </DocumentItem>
      ))}
    </DocumentListContainer>
  );
};

export default DocumentList;
