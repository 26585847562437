import React from 'react';
import {
    FooterContainer,
    FooterContent,
    LogoSection,
    FooterLogo,
    Address,
    NavSection,
    FooterNav,
    FooterLink,
    SubscribeSection,
    SubscribeButton
} from './Footer.styles';
import logo from "../../assets/media/logoCDC.svg";
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <>
            <FooterContainer>
                <FooterContent>
                    <LogoSection>
                        <Link to="/">
                            <FooterLogo src={logo} alt="CDC RDN" />
                        </Link>
                        <Address>Corporation de développement communautaire de la Rivière-du-Nord</Address>
                        <Address>1270 boulevard des Laurentides, Saint-Jérôme, Québec, J7Z 7L3</Address>
                        <Address>450-912-8005</Address>
                    </LogoSection>
                    <NavSection>
                        <FooterNav>
                            <FooterLink to="/">Accueil</FooterLink>
                            <FooterLink to="/organisation">Notre organisme</FooterLink>
                            <FooterLink to="/notre-equipe">Notre équipe</FooterLink>
                            <FooterLink to="/conseil-administration">Membres du CA</FooterLink>
                        </FooterNav>
                    </NavSection>
                    <NavSection>
                        <FooterNav>
                            <FooterLink to="/membres">Membres</FooterLink>
                            <FooterLink to="/projets-collectifs">Projets collectifs</FooterLink>
                            <FooterLink to="/publication">Publications</FooterLink>
                            <FooterLink to="/contact">Nous joindre</FooterLink>
                        </FooterNav>
                    </NavSection>
                    <SubscribeSection>
                        <h3>Restez à l'affût!</h3>
                        <a href='https://app.cyberimpact.com/clients/52894/subscribe-forms/E79C91E0-3164-49A1-813F-10AA99003920'>
                            <SubscribeButton>je m'inscris à l'info-lettre</SubscribeButton>
                        </a>
                    </SubscribeSection>
                </FooterContent>
            </FooterContainer>
        </>
    );
};