import React, { useState } from 'react';
import { Text } from '../common';
import { FAQItem, Content, Container } from './FAQContainer.styles';

export const FAQContainer = ({ title, content }) => {
    const [isopen, setIsOpen] = useState(false);

    const toggleContent = () => {
        setIsOpen(!isopen);
    };

    return (
        <Container>
            <FAQItem onClick={toggleContent} isopen={isopen}>
                <Text><strong onClick={toggleContent}>{title}</strong></Text>
                <svg stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="m18.5 9-6 6-6-6"></path>
                </svg>
            </FAQItem>
            {isopen && <Content>{content}</Content>}
        </Container>
    );
};