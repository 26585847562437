import styled from 'styled-components';
import { Link } from 'react-router-dom';

const baseStyles = `
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

export const Content = styled.div`
    ${baseStyles}
`;

export const ArticlesList = styled.article`
    ${baseStyles}
    color: #333;

    h1, h2, h3, h4, h5, h6 {
        color: #333;
    }
`;

export const BlogContent = styled(Content)`
    padding: 0;
    width: 100%;
    
    h1 {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 1rem;
        color: #333;
        text-align: left;
    }

    a {
        color: #6a7fca;
        text-decoration: none;
    }
`;

export const SlimContent = styled(Content)`
    width: 50%;
    max-width: 600px;

    @media (max-width: 768px) {
        width: 80%;
    }
`;

export const Block = styled.div`
    position: relative;
    width: 100%;
    padding: 2rem 0;
    background: ${props => {
        if (props.background && typeof props.background === 'string' && (props.background.startsWith('url'))) {
            return `linear-gradient(rgba(50, 60, 90, 0.7), rgba(50, 60, 90, 0.7)), ${props.background} center center / cover no-repeat`;
        }
        return props.background || 'transparent';
    }};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;

    p {
        max-width: 960px;
    }

    ${Content} {
        color: ${props => props.background ? 'var(--color-light)' : 'var(--color-dark)'};
    }
`;

export const JumboBlock = styled(Block)`
    padding: 4rem 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    @media (max-width: 768px) {
    background-attachment: scroll;
    }
`;

export const HeroBlock = styled(Block)`
    background: #f2f5fd;
`;

export const Separator = styled.div`
    width: 100%;
    height: 2rem;
`;

export const MainHeading = styled.h1`
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #5d7dcf;
    text-align: left;
`;

export const PostHeading = styled.h1`
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #5d7dcf;
    text-align: left;
    display: flex;
    gap: 1rem;
    align-items: center;
`;

export const SubHeading = styled.h2`
    width: 100%;
    font-size: 2rem;
    font-weight: 500;
    color: #fff;

    @media (max-width: 1085px) {
        font-size: 1.8rem;
    }
`;

export const ProjectHeading = styled.h2`
    font-size: 2.2rem;
    font-weight: 700;
    color: #333;
`;

export const ProjectSubHeading = styled.h3`
    max-width: 600px;
    font-size: 1.5rem;
    font-weight: 500;
    color: #333;
`;

export const InfoHeading = styled.h3`
    font-size: 1.5rem;
    font-weight: 500;

    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`;

export const Blockquote = styled.blockquote`
    font-size: 1rem;
    font-style: italic;
    padding: 0.5rem 1rem;
    border-left: 2px solid #6a7fca;
    margin: 1rem 0;
`;

export const Paragraph = styled.p`
    font-size: 1rem;
    line-height: 1.6;
    text-align: left;

    a {
        color: #6a7fca;
        text-decoration: none;
    }
`;

export const Text = styled(Paragraph)`
    max-width: unset;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
`;

export const Redirect = styled(Link)`
    color: #6a7fca;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;

export const Hyperlink = styled.a`
    color: #6a7fca;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
`;

export const List = styled.ul`
    list-style-type: circle;
    padding-left: 1.5rem;
    margin: 1rem 0;
`;

export const ListItem = styled.li`
    padding: 0.25rem 0;
`;

export const Image = styled.img`
    max-width: 100%;
    width: ${props => props.width ? `${props.width}px` : '100%'};
    height: ${props => props.height ? `${props.height}px` : 'auto'};
    margin: 1rem 0;
`;

export const Inline = styled.div`
    display: flex;
    gap: 2rem;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

export const WrapContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    max-width: 600px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    @media (max-width: 768px) {
        align-items: stretch;
    }
`;

export const Button = styled.button`
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: var(--radius);
    background: ${props =>
        props.primary ? 'var(--color-primary)' :
        props.secondary ? 'var(--color-secondary)' :
        props.tertiary ? 'var(--color-tertiary)' :
        'transparent'
    };
    color: var(--color-light);
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 0.9;
        transform: scale(0.98);
    }
`;

export const Input = styled.input`
    padding: 0.5rem 1rem;
    border: 1px solid var(--color-border);
    border-radius: var(--radius);
    font-size: 1rem;
    width: 100%;
`;

export const Textarea = styled.textarea`
    padding: 0.5rem 1rem;
    border: 1px solid var(--color-border);
    border-radius: var(--radius);
    font-size: 1rem;
    width: 100%;
    min-height: 100px;
`;

export const Card = styled.div`
    padding: 2rem;
    background: #fff;
    border-radius: var(--radius);

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

export const Article = styled.article`
    padding: 2rem;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

export const ScrollableContainer = styled.div`
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;
`;