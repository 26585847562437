import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  FullScreenBanner,
  ContentContainer,
  NumbersContainer,
  NumberBlock,
  Description
} from './AnimatedInfoBlock.styles';
import { SubHeading } from '../common';

const AnimatedInfoBlock = () => {
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  React.useEffect(() => {
    let timer1, timer2;
    if (inView) {
      timer1 = setInterval(() => {
        setCount1(prevCount => (prevCount < 44 ? prevCount + 1 : prevCount));
      }, 50);

      timer2 = setInterval(() => {
        setCount2(prevCount => (prevCount < 5 ? prevCount + 1 : prevCount));
      }, 400);
    }

    return () => {
      clearInterval(timer1);
      clearInterval(timer2);
    };
  }, [inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <FullScreenBanner ref={ref}>
      <ContentContainer
        as={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <NumbersContainer>
          <NumberBlock as={motion.div} variants={itemVariants}>
            {count1}
            <span>membres</span>
          </NumberBlock>
          <NumberBlock as={motion.div} variants={itemVariants}>
            {count2}
            <span>municipalités</span>
          </NumberBlock>
        </NumbersContainer>
        <SubHeading as={motion.h2} variants={itemVariants}>
            Notre mission
        </SubHeading>
        <Description as={motion.p} variants={itemVariants}>
          Nous avons pour mission d'assurer la participation active et concertée des
          organismes membres au développement socioéconomique de leur milieu,
          dans l'optique d'améliorer la qualité de vie des personnes qui y vivent.
        </Description>
      </ContentContainer>
    </FullScreenBanner>
  );
};

export default AnimatedInfoBlock;