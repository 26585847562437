import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
  background-color: #fff;
  color: #333;
  padding: 2rem 1rem;
`;

export const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1085px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1085px) {
    margin-bottom: 2rem;
  }
`;

export const FooterLogo = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 1rem;
`;

export const Address = styled.p`
  font-size: 0.9rem;
  margin: 0;
`;

export const NavSection = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 1085px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const FooterNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & a {
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1085px) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
    font-size: 1rem;
  }
  }

  @media (max-width: 1085px) {
    width: 100%;
  }
`;

export const FooterLink = styled(Link)`
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1085px) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
    font-size: 1rem;
  }
`;

export const SubscribeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1085px) {
    order: -1;
    margin-bottom: 2rem;
    width: 100%;
  }
`;

export const SubscribeButton = styled.button`
  background-color: var(--color-primary);
  color: white;
  font-weight: 600;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--radius);
  cursor: pointer;
  font-size: 1rem;
  margin-top: 0.5rem;
  padding: 1rem 2rem;

  &:hover {
     scale: 0.98;
        opacity: 0.9;
        transition: scale 0.2s ease-in-out, opacity 0.1s ease-in-out;
  }

  @media (max-width: 1085px) {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
  }
`;