import { useEffect, useState } from 'react';
import {sanityClient} from '../utils/sanity/client';

export function useCoProjects() {
  const client = sanityClient;

  const [coProjects, setCoProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const query = '*[_type == "ProjetsCollectifs"]';

    client.fetch(query)
      .then(projects => {
        setCoProjects(projects);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [client]);

  return { coProjects, isLoading, error };
}
