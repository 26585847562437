import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useCoProjects } from '../hooks/useCoProjects';
import {
    Content, Separator, MainHeading, ProjectHeading, ProjectSubHeading, Paragraph, Redirect, List, ListItem, Column, WrapContainer, Card, Text,
    Image,
    Blockquote,
} from '../components/common';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import { sanityClient } from '../utils/sanity/client';

const FilterButton = styled.button`
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: 1px solid ${props => props.isselected ? 'var(--color-secondary)' : '#00000000'};
    background-color: ${props => props.isselected ? 'var(--color-secondary)' : '#00000020'};
    border-radius: 2px;
    padding: 0.5rem 1rem;
    color: ${props => props.isselected ? 'white' : '#333'};
    
    &:hover {
        background-color: ${props => props.isselected ? 'var(--color-secondary)' : '#00000040'};
        opacity: 0.8;
    }
`;

function urlFor(source) {
    return imageUrlBuilder(sanityClient).image(source);
}

export default function ProjectsCollectifs() {
    const { coProjects, isLoading, error } = useCoProjects();
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('Recent');
    
    useEffect(() => {
        if (!isLoading && !error) {
            setFilteredProjects(coProjects);
        }
    }, [isLoading, error, coProjects]);

    const filterByName = (name) => {
        setSelectedFilter(name);
        setFilteredProjects(coProjects.filter(project => project.title === name));
    };

    const resetFilter = () => {
        setSelectedFilter('Recent');
        setFilteredProjects(coProjects);
    };

    if (isLoading) return <div></div>;
    if (error) return <div>Error: {error.message}</div>;
    if (!coProjects || coProjects.length === 0) {
        return <div>No projects found.</div>;
    }
    
    // Separate "autres projets" from other projects
    const autresProjets = coProjects.find(project => project.title.toLowerCase() === "autres projets");
    const otherProjects = coProjects.filter(project => project.title.toLowerCase() !== "autres projets");

    // Serializers for handling different content types
    const serializers = {
        types: {
            block: ({ node }) => {
                const { style = 'normal' } = node;

                const content = node.children.map((child, index) => {
                    if (child._type === 'span') {
                        if (child.text === '') {
                            return <br key={index} />;
                        }
                        
                        // Check if the span has any marks
                        if (child.marks && child.marks.length > 0) {
                            // Handle text marks (bold, italic, etc.)
                            let textContent = child.text;
                            
                            // Wrap the text in appropriate styling based on marks
                            if (child.marks.includes('strong')) {
                                textContent = <strong>{textContent}</strong>;
                            }
                            if (child.marks.includes('em')) {
                                textContent = <em>{textContent}</em>;
                            }
                            if (child.marks.includes('underline')) {
                                textContent = <u>{textContent}</u>;
                            }
                            
                            // Handle links
                            const linkMark = child.marks.find(mark => 
                                node.markDefs?.find(def => def._key === mark && def._type === 'link')
                            );
                            
                            if (linkMark) {
                                const linkDef = node.markDefs.find(def => def._key === linkMark);
                                return (
                                    <Redirect 
                                        key={index}
                                        to={linkDef.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {textContent}
                                    </Redirect>
                                );
                            }
                            
                            return <span key={index}>{textContent}</span>;
                        }
                        
                        return (
                            <span key={index} style={{ whiteSpace: 'pre-wrap' }}>
                                {child.text}
                            </span>
                        );
                    } else if (child._type === 'hardBreak') {
                        return <br key={index} />;
                    }
                    return null;
                });

                const WrapperComponent = style === 'normal' ? Text :
                                         style === 'h1' ? MainHeading :
                                         style === 'h2' ? ProjectSubHeading :
                                         style === 'blockquote' ? Blockquote : Text;

                return (
                    <WrapperComponent style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                        {content}
                    </WrapperComponent>
                );
            },
            image: ({ node }) => (
                <Image
                    src={urlFor(node.asset._ref).width(800).fit('max').auto('format').url()}
                    alt={node.alt || ' '}
                    loading="lazy"
                    width={node.size}
                />
            ),
            link: ({ node }) => (
                <Redirect to={node.link} target="_blank" rel="noopener noreferrer">
                    {node.link}
                </Redirect>
            ),
        },
        marks: {
            link: ({ mark, children }) => (
                <Redirect 
                    to={mark.href} 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    {children}
                </Redirect>
            ),
        },
        list: props => <List>{props.children}</List>,
        listItem: props => <ListItem>{props.children}</ListItem>,
    };

    return (
        <Content>
            <MainHeading>Projets collectifs</MainHeading>
            <Paragraph>La CDC RDN  travaille régulièrement sur des projets de développement local avec les autres acteurs politiques, institutionnels et socioéconomiques du territoire. </Paragraph>
            <Separator />
            
            {/* Filter Buttons */}
            <WrapContainer>
                <FilterButton 
                    onClick={resetFilter} 
                    isselected={selectedFilter === 'Recent'}
                >
                    Recent
                </FilterButton>
                {otherProjects.map((project, index) => (
                    <FilterButton 
                        key={index} 
                        onClick={() => filterByName(project.title)}
                        isselected={selectedFilter === project.title}
                    >
                        {project.title}
                    </FilterButton>
                ))}
                {autresProjets && (
                    <FilterButton 
                        onClick={() => filterByName(autresProjets.title)}
                        isselected={selectedFilter === autresProjets.title}
                    >
                        {autresProjets.title}
                    </FilterButton>
                )}
            </WrapContainer>
            <Separator />
            
            {/* Display Projects */}
            {filteredProjects.map((project, index) => (
                <Card key={index}>
                    <Column>
                        <ProjectHeading>{project.title}</ProjectHeading>
                        <BlockContent
                            blocks={project.content}
                            serializers={serializers}
                            projectId={sanityClient.config().projectId}
                            dataset={sanityClient.config().dataset}
                            renderContainerOnSingleChild={true}
                            ignoreUnknownTypes={false}
                        />
                    </Column>
                </Card>
            ))}
        </Content>
    );
}