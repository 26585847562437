import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    padding: 25px;
    background-color: var(--color-background);
`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 1085px) {
        flex-direction: column;
        gap: 16px;
    }

    @media (max-width: 768px) {
           align-items: flex-start;
    }
`;

export const HeaderLogo = styled.img`
    height: 70px;
    width: auto;
    margin-right: auto;

    @media (max-width: 768px) {
        height: 80px;
    }
`;

export const HeaderLogoMobile = styled(HeaderLogo)`
    margin-bottom: 2rem;
    top: 25px;
    left: 25px;
    height: 60px;
    `;

export const HeaderNav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-left: auto;

  & a:last-child {
    color: #ff6ba1;
  }

    @media (max-width: 1085px) {
        margin-left: 0;
    }

  @media (max-width: 768px) {
    display: none;
  }
`;


export const HeaderLink = styled(Link)`
    text-decoration: none;
    color: #000;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;

    &:hover {
        scale: 0.98;
        opacity: 0.8;
        border-bottom: 2px solid #00000040;
    }

    &:active,
    &.active { 
        border-bottom: 2px solid #5d7dcf;
    }
`;

export const MobileLink = styled(HeaderLink)`
    text-decoration: none;
    color: #FFF;
    font-weight: 600;
    font-size: 1.4rem;
    border-bottom: 2px solid transparent;

    &:hover {
        scale: 0.98;
        opacity: 0.8;
        border-bottom: 2px solid #00000040;
    }

    &:active,
    &.active {  
        border-bottom: 2px solid #5d7dcf;
    }
`;

export const HeaderDropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover .dropdown-content {
    display: block;
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px;

  a {
    color: black !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 0.9rem;

    &:hover {
      background-color: #f1f1f1;
    }
  }
`;

export const MobileDropdownContent = styled.div`
  display: none;
  
  a {
    color: black !important;
    padding: 5px 16px;
    text-decoration: none;
    font-size: 1.2rem;
    text-align: left;
    display: block;

    &:hover {
      background-color: #f1f1f1;
    }

      &::before {
    content: "-";
    color: black;
    display: inline-block;
    margin-right: 5px;
    }
    }


  &.open {
    display: block;
  }
`;