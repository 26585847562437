import React from "react";
import { Content, JumboBlock, MainHeading, Paragraph } from "../components/common";
import MembersGrid from "../components/MembersGrid/MembersGrid";
import { useMembers } from "../hooks/useMembers";

export default function Members() {
  const { members, isLoading, error } = useMembers();

  if (isLoading) return <div></div>;
  if (error) return <div>Error: {error.message}</div>;
  const memberList = members.result;

  return (
    <JumboBlock>
      <Content>
        <MainHeading>Nos membres</MainHeading>
        <Paragraph>
          Explorez la diversité des organismes réunis par la CDC RDN, avec plus de 40 membres actifs dans une variété de domaines. Cliquez sur le logo de chaque organisme pour accéder directement à leur site web ou page Facebook.
        </Paragraph>
      </Content>

      <Content>
        <MembersGrid members={memberList} />
      </Content>
    </JumboBlock>
  );
}
