import { useEffect, useState } from 'react';
import {sanityClient} from '../utils/sanity/client';

export function useMembers() {
    const client = sanityClient;

    const [members, setMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const query = '*[_type == "Membres"]';

        client.fetch(query)
            .then(data => {
                setMembers(data);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [client]);

    return { members, isLoading, error };
}
