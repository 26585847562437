import styled from 'styled-components';

export const FullScreenBanner = styled.div`
  background: #f2f5fd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
  color: var(--color-dark); 
`;

export const ContentContainer = styled.div`
  max-width: 900px;
  width: 100%;
  text-align: center;
  color: var(--color-dark); 
  padding: 2rem;
  border-radius: 8px;

  & h2 {
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 2rem;
    color:#5d7dcf;
  }
`;

export const NumbersContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
  margin-bottom: 3rem;
`;

export const NumberBlock = styled.div`
  font-size: 3.5rem;
  font-weight: 700; 
  color: #5d7dcf;

  span {
    display: block;
    font-size: 1.25rem; 
    margin-top: 0.5rem;
    color: var(--color-dark); 
    font-weight: 400;
  }
`;

export const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.75;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  color: var(--color-text);
  padding-top: 1.5rem;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
